import CALENDAR_ICON from '../icons/calendar.svg';
import CLOSE_ICON from '../icons/close.svg';
import { appendPositionToClassName } from '../utils';
import Icon from './Icon';

export default function Button(position, color, themeColor, onClick) {
  const button = document.createElement('button');
  const hoverOverlay = document.createElement('div');
  hoverOverlay.className = 'SBW-button--overlay';

  button.className = appendPositionToClassName(
    'SBW-button SBW-button--bounce',
    position
  );

  button.style.opacity = 0;
  button.style.pointerEvents = 'none';
  button.style.color = color;
  button.style.backgroundColor = themeColor;
  button.style.backgroundImage = 'none';
  button.onclick = onClick;

  return button;
}

function removeStaleDate(button) {
  while (button.firstChild) {
    button.removeChild(button.firstChild);
  }
}

export function setDefaultButtonContent(button, text) {
  removeStaleDate(button);
  button.appendChild(Icon(CALENDAR_ICON));
  button.appendChild(document.createTextNode(text));
  return button;
}

export function setCloseButtonContent(button) {
  removeStaleDate(button);
  button.appendChild(Icon(CLOSE_ICON));
  return button;
}
