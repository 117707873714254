import uuidv4 from 'uuid-v4';

const SHORE_CUSTOMER_UUID = 'shore_customer_uuid';
const SHORE_CONNECT_PROD = 'https://connect.shore.com';
const SHORE_CONNECT_STAGING = 'https://staging-connect.shore.com';

function getCid() {
  if (!window.localStorage) {
    return uuidv4();
  }

  const uuid = window.localStorage.getItem(SHORE_CUSTOMER_UUID);

  if (uuid) {
    return uuid;
  }

  const newUuid = uuidv4();
  window.localStorage.setItem(SHORE_CUSTOMER_UUID, newUuid);
  return newUuid;
}

function urlEncode(obj) {
  return Object.keys(obj)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    })
    .join('&');
}

/**
 * Add event listener to merchant site `window` object
 * for tracking events inside GA through Google Tag Manager.
 */
export const addGTMTrackEventListener = () => {
  window.addEventListener('message', e => {
    if (
      (e.origin === SHORE_CONNECT_PROD || e.origin === SHORE_CONNECT_STAGING) &&
      window.dataLayer
    ) {
      if (
        typeof window.dataLayer.push === 'function' &&
        e.data &&
        e.data.event &&
        e.data.eventAction &&
        e.data.eventCategory
      ) {
        window.dataLayer.push({
          event: e.data.event,
          eventAction: e.data.eventAction,
          eventCategory: e.data.eventCategory,
          eventLabel: e.data.eventLabel,
          eventValue: e.data.eventValue,
        });
      }
    }
  });
};
