export function isValidHexColor(color) {
  var regex = /[0-9A-Fa-f]{6}/g;
  return regex.test(color);
}

export function getColor(color) {
  return color;
}

export function appendPositionToClassName(className, position) {
  const isValidClass = function(name) {
    return typeof name === 'string';
  };

  return [className, position === 'left' ? 'SBW-left' : 'SBW-right']
    .filter(isValidClass)
    .join(' ');
}

export function hideElement(element) {
  return element.className + ' SBW-element--hide';
}

export function removeClassName(element, className) {
  const classes = element.className.split(' ');
  return classes
    .filter(function(c) {
      return c !== className;
    })
    .join(' ');
}

export function showElement(element) {
  return removeClassName(element, 'SBW-element--hide');
}

export function isLoaded() {
  return window.ShoreBookingButtonAlreadyLoaded === true;
}

export function setLoadedStatus(status) {
  window.ShoreBookingButtonAlreadyLoaded = status;
}
