import { isValidHexColor } from '../utils';

const BASE_URL_REGEX = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/;

function getLocale(locale) {
  return (
    locale ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    process.env.DEFAULT_LOCALE
  );
}

function getBookingWidgetScript(scripts) {
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf('/widget/booking.js') !== -1) {
      return scripts[i];
    }
  }

  return undefined;
}

function getScriptBaseUrl() {
  const scripts = [].slice.call(document.getElementsByTagName('script'));
  const bookingSnippetScript = getBookingWidgetScript(scripts);

  if (!bookingSnippetScript) {
    return process.env.WIDGET_URL;
  }

  const results = BASE_URL_REGEX.exec(bookingSnippetScript.src);
  return results && results.length > 0 ? results[0] : process.env.WIDGET_URL;
}

export function getBookingUrl(settings) {
  const company = settings.company;
  const baseUrl = getScriptBaseUrl();
  const themeColor = settings.themeColor;

  let src = [
    baseUrl,
    '/widget/',
    company,
    '?locale=',
    getLocale(settings.locale),
    '&layout=',
    'nolayout',
    '&select_location=',
    settings.selectLocation || false,
  ].join('');

  if (settings.gaId) {
    src = [src, '&', settings.gaId].join('');
  }

  if (isValidHexColor(themeColor)) {
    src = [src, '&theme_color=', themeColor.replace(/#/g, '')].join('');
  }

  return src;
}

export function getBookingIframe(settings) {
  const iframe = document.createElement('iframe');

  iframe.src = getBookingUrl(settings);

  return iframe;
}
